import React from 'react';
import Layout from '../components/Layout';
import WideSection from '../components/WideSection';
import { graphql } from 'gatsby';
import PreviewCompatibleImage, { ImageInfo } from '../components/PreviewCompatibleImage';
import { HTMLContent } from '../components/Content';

interface WideSection {
  title: string;
  content: string;
  image: ImageInfo;
}

interface AboutPageTemplateProps {
  sections: WideSection[];
  profile: {
    biography: string;
    image: ImageInfo;
  };
}

export const AboutPageTemplate: React.SFC<AboutPageTemplateProps> = (props) => {
  return (
    <>
      <div className="hero">
        <div className="hero-body">
          <h1 className="title has-text-centered">About Us</h1>
        </div>
      </div>
      {props.sections.map((section, index) => {
        const content = <HTMLContent className="column is-7" content={section.content} />;
        return (
          <WideSection key={index}>
            <h2>{section.title}</h2>
            <div className="columns">
              {index / 2 ? null : content}
              <div className="column is-5 is-vcentered">
                <PreviewCompatibleImage imageInfo={section.image} />
              </div>
              {index / 2 ? content : null}
            </div>
          </WideSection>
        );
      })}
      <WideSection>
        <h2>Pastor</h2>
        <h3>Kevin Jenkins</h3>
        <div className="columns">
          <div className="column is-6 is-vcentered">
            <PreviewCompatibleImage imageInfo={props.profile.image} />
          </div>
          <div className="column is-6 is-vcentered">
            <p className="is-3">{props.profile.biography}</p>
          </div>
        </div>
      </WideSection>
      <WideSection>
        <h2 id="map">Location</h2>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d52998.64380306811!2d-88.00707302855557!3d39.15976208605691!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x9997787a2aa68394!2sNEW%20HOPE%20CUMBERLAND%20PRESBYTERIAN%20CHURCH!5e0!3m2!1sen!2sus!4v1594413693246!5m2!1sen!2sus"
          style={{ display: 'block', margin: '0 auto', width: '100%', height: '75vh' }}
          aria-hidden="false"
        />
      </WideSection>
    </>
  );
};

export const AboutPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <AboutPageTemplate sections={post.frontmatter.sections} profile={post.frontmatter.profile} />
    </Layout>
  );
};

export const aboutPageQuery = graphql`
  query aboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        sections {
          title
          content
          image {
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 526, quality: 92) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        profile {
          biography
          image {
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 526, quality: 92) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default AboutPage;
