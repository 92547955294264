import React from 'react';

export interface WideSectionProps {}

const WideSection: React.SFC<WideSectionProps> = (props) => {
  return (
    <section className="section">
      <div className="container wide-container content">{props.children}</div>
    </section>
  );
};

export default WideSection;
